










import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { DraftViewModel } from '../../../viewmodels/draft-viewmodel'

@Observer
@Component({
  components: {
    'post-list-item': () => import('./post-list-item.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class PostLists extends Vue {
  @Inject() vm!: DraftViewModel
  loadMore() {
    if (this.vm.posts?.canLoadMorePost) {
      this.vm.posts.loadMore()
    }
  }
}
